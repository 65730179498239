var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "100%", persistent: "", "max-width": "900" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "template",
            { slot: "progress" },
            [
              _c("v-progress-linear", {
                attrs: { color: "#006c27", height: "10", indeterminate: "" },
              }),
            ],
            1
          ),
          _c(
            "v-card-title",
            [
              _c("v-icon", [_vm._v("mdi-security")]),
              _c("div", { staticClass: "ml-2 text--secondary" }, [
                _vm._v("IT Security By Design"),
              ]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  items: _vm.demands,
                  label: "Select your IT Security Demand",
                  dense: "",
                  "return-object": "",
                  "item-text": "Title",
                  "item-value": "SecId",
                  "hide-details": "",
                  color: "#006c27",
                  filter: _vm.filterDemands,
                },
                on: { change: _vm.updateDemand },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(item.Title) },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-list-item-action-text", {
                              domProps: { textContent: _vm._s(item.SecId) },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.demand,
                  callback: function ($$v) {
                    _vm.demand = $$v
                  },
                  expression: "demand",
                },
              }),
              _vm.demands.length != 0
                ? _c("div", { staticClass: "ml-1" }, [
                    _vm._v(
                      " Last updated: " +
                        _vm._s(_vm.lastUpdate) +
                        ", Total: " +
                        _vm._s(_vm.demands.length) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-3", staticStyle: { color: "red" } },
                [
                  _vm._v(
                    " Please select the IT Security Demand of the application where you plan to integrate the API(s). "
                  ),
                  _c("br"),
                  _vm._v("Do "),
                  _c("u", [_vm._v("not")]),
                  _vm._v(
                    " select a platform like Mendix or Powerapps. For applications on these platforms you need an own IT Security Demand."
                  ),
                  _c("br"),
                  _vm._v(
                    " In case you integrate an API into an existing application, make sure you also update your IT Security Demand accordingly! "
                  ),
                ]
              ),
              _vm.demand
                ? _c(
                    "div",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-information-outline"),
                          ]),
                          _c("span", {}, [_vm._v("Demand details")]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "Title",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.demand.Title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "Title", $$v)
                                  },
                                  expression: "demand.Title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "ID",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.demand.SecId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "SecId", $$v)
                                  },
                                  expression: "demand.SecId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "Confidentiality",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.demand.Confidentiality,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "Confidentiality", $$v)
                                  },
                                  expression: "demand.Confidentiality",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "Status",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.demand.Status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "Status", $$v)
                                  },
                                  expression: "demand.Status",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "Region",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                },
                                model: {
                                  value: _vm.demand.Region,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "Region", $$v)
                                  },
                                  expression: "demand.Region",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Protection Manager",
                                  items: _vm.demand.ProtectionManager,
                                  "item-text": "Name",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.demand.firstProtectionManager,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.demand,
                                      "firstProtectionManager",
                                      $$v
                                    )
                                  },
                                  expression: "demand.firstProtectionManager",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Service Owner",
                                  items: _vm.demand.ServiceOwner,
                                  "item-text": "Name",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.demand.firstServiceOwner,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.demand,
                                      "firstServiceOwner",
                                      $$v
                                    )
                                  },
                                  expression: "demand.firstServiceOwner",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-textarea", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: "Description",
                                  outlined: "",
                                  readonly: "",
                                  color: "#006c27",
                                  "no-resize": "",
                                },
                                model: {
                                  value: _vm.demand.Description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.demand, "Description", $$v)
                                  },
                                  expression: "demand.Description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "mt-5" }, [
                    _c("div", [
                      _vm._v(
                        " In case you are not familiar with the IT Security By Design process, find below some useful links. "
                      ),
                    ]),
                    _c("div", { staticClass: "mt-3" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: "https://worksite.sharepoint.com/sites/Digitalization-and-IT-Services-Support/SitePages/Security-by-Design.aspx",
                            target: "_blank",
                          },
                        },
                        [_vm._v("IT Security at MySchaeffler")]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: "https://apicademy.dp.schaeffler/base-boarding?module=what-is-it-security-by-design",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" IT Security at APIcademy ")]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: "https://www.schaeffler-academy-learning.com/ilp/pages/catalogsearch.jsf?locale=de-DE&showbundlekeys=false&sidebarExpanded=true&q=%7B!q.op%3DAND%7D%20%7B!q.op%3DAND%7D%20IT%20Security%20by%20Design&rows=3&fq=%7B!tag%3DlearningFormId%7D%20learningFormId:(113)",
                            target: "_blank",
                          },
                        },
                        [_vm._v("IT Security at Schaeffler Academy ")]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: {
                            href: "http://qmh.de.ina.com:1080/SAPIKS2/contentShow.sap?_CLASS=QM_DOC&_LOIO=C6A0E6D5672248E9AB8414694C47C94C&&IWB_INDUSTRY=/KWCUST/I1&TMP_IWB_TASK=DISPLAY&RELEASE=10&LANGUAGE=EN",
                            target: "_blank",
                          },
                        },
                        [_vm._v("IT Security at Management Handbook")]
                      ),
                    ]),
                  ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _vm.demand
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 white--text",
                      attrs: { color: "#006c27" },
                      on: { click: _vm.showDemand },
                    },
                    [_vm._v(" Show demand ")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { disabled: !_vm.demand, color: "#006c27" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" OK ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#006c27" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }