var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto mb-7",
                  attrs: { loading: _vm.loading, "max-width": "900" },
                },
                [
                  _c("error-overlay", {
                    on: { retry: _vm.loadData },
                    model: {
                      value: _vm.error,
                      callback: function ($$v) {
                        _vm.error = $$v
                      },
                      expression: "error",
                    },
                  }),
                  _c(
                    "template",
                    { slot: "progress" },
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          color: "#006c27",
                          height: "10",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "text--secondary" },
                    [
                      _vm._v(" Permission Details "),
                      _c("v-spacer"),
                      _vm.permission.environment != ""
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                color: _vm.getEnvironmentColor(
                                  _vm.permission.environment
                                ),
                                dark: "",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.permission.environment) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary pb-2" },
                    [
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            value: _vm.permission.applicationDisplayname,
                            label: "Application Name",
                            outlined: "",
                            readonly: "",
                            dense: "",
                            "hide-details": "",
                            color: "#006c27",
                          },
                        },
                        [
                          _vm.permission.showAzureLinks
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { slot: "append", bottom: "" },
                                  slot: "append",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "2px",
                                                    },
                                                    attrs: { dense: "" },
                                                    on: { click: _vm.linkApp },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-link ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2087731886
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Link to the Consumer AppReg"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            value: _vm.getITSecDemandText(),
                            label: "IT Security Demand",
                            outlined: "",
                            dense: "",
                            readonly: "",
                            "hide-details": "",
                            error: !_vm.loading && !_vm.hasITSecDemand(),
                            color: "#006c27'",
                          },
                        },
                        [
                          _vm.hasITSecDemand()
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { slot: "append", bottom: "" },
                                  slot: "append",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "2px",
                                                    },
                                                    attrs: { dense: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.linkITSec(
                                                          _vm.permission
                                                            .itSecURL
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-link ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    596544711
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Link to the IT Security Demand"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            value: _vm.permission.name,
                            label: "Permission Name",
                            outlined: "",
                            dense: "",
                            readonly: "",
                            "hide-details": "",
                            color: "#006c27",
                          },
                        },
                        [
                          _vm.permission.showAzureLinks
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { slot: "append", bottom: "" },
                                  slot: "append",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      "margin-top": "2px",
                                                    },
                                                    attrs: { dense: "" },
                                                    on: { click: _vm.linkApi },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-link ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3975185271
                                  ),
                                },
                                [_c("span", [_vm._v("Link to the API AppReg")])]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-textarea", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.permission.description,
                          label: "Permission Description",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          "auto-grow": true,
                          rows: "1",
                          color: "#006c27",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-3",
                        attrs: {
                          value: _vm.formatString(_vm.permission.type),
                          label: "Permission Model",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-3",
                        class: _vm.permission.events == null ? "my-3 " : "mt-3",
                        attrs: {
                          value: _vm.permission.tokenScope,
                          label: "Token Scope",
                          outlined: "",
                          dense: "",
                          readonly: "",
                          "hide-details": "",
                          color: "#006c27",
                        },
                      }),
                      _vm.permission.events != null
                        ? _c(
                            "v-expansion-panels",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "v-expansion-panel",
                                {
                                  attrs: {
                                    disabled: _vm.loading || _vm.error != null,
                                  },
                                },
                                [
                                  _c("v-expansion-panel-header", [
                                    _c("div", [
                                      _vm._v(" API Responsible "),
                                      _vm.permission.DDMpresent != undefined &&
                                      !_vm.permission.DDMpresent
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "font-size": "1.1rem",
                                                  },
                                                  attrs: { color: "red" },
                                                },
                                                [_vm._v("mdi-alert")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _vm._l(
                                        _vm.permission.responsible,
                                        function (resp) {
                                          return _c(
                                            "div",
                                            { key: resp.id },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(resp.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("v-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "right",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  resp.role
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass:
                                                              "ml-2 white--text",
                                                            attrs: {
                                                              color: "#006c27",
                                                              small: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openChat(
                                                                  resp
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Open Chat "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _vm.permission != undefined &&
                                      _vm.permission.responsible != undefined &&
                                      _vm.permission.responsible.length != 1
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "right",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            staticClass:
                                                              "ml-2 white--text",
                                                            attrs: {
                                                              color: "#006c27",
                                                              small: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openTeamChat()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "    Open Chat with whole team    "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.permission.DDMpresent
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Problem detected! No DDM configured for this API Permission. This will be reported. Please fix asap. "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel",
                                {
                                  attrs: {
                                    disabled: _vm.loading || _vm.error != null,
                                  },
                                },
                                [
                                  _c("v-expansion-panel-header", [
                                    _vm._v(" Audit Events "),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-timeline",
                                        {
                                          staticStyle: {
                                            "margin-left": "-30px",
                                          },
                                          attrs: { dense: "" },
                                        },
                                        _vm._l(
                                          _vm.permission.events,
                                          function (events, index) {
                                            return _c(
                                              "v-timeline-item",
                                              {
                                                key: events.createdAt,
                                                attrs: {
                                                  color:
                                                    index === 0
                                                      ? "#006c27"
                                                      : "grey",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  { staticClass: "pt-1" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "2" } },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatDate"
                                                              )(
                                                                events.createdAt
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                        attrs: {
                                                          cols: "6",
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  events.state
                                                                ) +
                                                                " "
                                                            ),
                                                            events.reasonText
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                              attrs,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "blur",
                                                                                              rawName:
                                                                                                "v-blur",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#006c27",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.showReasonDialog(
                                                                                                events
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      "v-icon",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-information-outline "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Additional Information:"
                                                                      ),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          events.reasonText
                                                                        ) + " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        events.ticketName
                                                          ? _c("div", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: _vm.getTickerLink(
                                                                      events
                                                                    ),
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      events.ticketName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        events.itSecDemand
                                                          ? _c("div", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: events.itSecURL,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      events.itSecDemand
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                        attrs: {
                                                          cols: "4",
                                                          "align-self":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              events.createdBy
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.permission.events != null
                    ? _c(
                        "v-card-actions",
                        { staticClass: "mr-3 pb-3" },
                        [
                          _vm.permission.showBackLink
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "white--text ml-2",
                                  attrs: { color: "#006c27" },
                                  on: { click: _vm.navToApp },
                                },
                                [_vm._v("BACK")]
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-dialog",
                            {
                              attrs: {
                                width: "100%",
                                persistent: "",
                                "max-width": "490",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.permission
                                                        .ImplementationDone,
                                                    expression:
                                                      "permission.ImplementationDone",
                                                  },
                                                ],
                                                staticClass: "white--text",
                                                attrs: {
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.error != null ||
                                                    _vm.implementDisabled,
                                                  color: "#006c27",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("Technical approval")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2416611200
                              ),
                              model: {
                                value: _vm.implementdialog,
                                callback: function ($$v) {
                                  _vm.implementdialog = $$v
                                },
                                expression: "implementdialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "#006c27" },
                                        },
                                        [_vm._v("mdi-wrench-check-outline")]
                                      ),
                                      _vm._v(" Technical Confirmation"),
                                    ],
                                    1
                                  ),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "By confirming the technical implemenation, Schaeffler Azure Global Admins will be informed to give the admin consent on this permission. After this step the requesting application will be able to obtain authentication tokens for the service. The user will have access to the API/Data. "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      " Please make sure all technical requirments are full-filled before pressing the confirm button. If you can not full-fill the technical implementation please get in contact with api-mgmt-support@schaeffler.com to close this permission request. "
                                    ),
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.implementdialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ConfirmImplementation()
                                            },
                                          },
                                        },
                                        [_vm._v(" Confirm ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: {
                                width: "100%",
                                persistent: "",
                                "max-width": "490",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.permission.grantable,
                                                    expression:
                                                      "permission.grantable",
                                                  },
                                                ],
                                                staticClass: "ml-2 white--text",
                                                attrs: {
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.error != null ||
                                                    _vm.actionDisabled,
                                                  color: "#006c27",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("Grant")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                745931925
                              ),
                              model: {
                                value: _vm.grantdialog,
                                callback: function ($$v) {
                                  _vm.grantdialog = $$v
                                },
                                expression: "grantdialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "#006c27" },
                                        },
                                        [_vm._v("mdi-shield-check-outline")]
                                      ),
                                      _vm._v("Grand Permission "),
                                    ],
                                    1
                                  ),
                                  !_vm.permission.threeStepFlow
                                    ? _c("v-card-text", [
                                        _vm._v(
                                          "By granting the permission, Schaeffler Azure Global Admins will be informed to give an admin consent on your grant. After this step the requesting application will be able to obtain authentication tokens for your service. "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(
                                          " Before granting the permission, please make sure all technical requirements are fullfilled for the user to consume the API "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v(
                                            " Your grant will be a Data Domain Manager approval. If you are not a DDM on this API/Data please contact api-mgmt-support@schaeffler.com. "
                                          ),
                                        ]),
                                      ])
                                    : _c("v-card-text", [
                                        _vm._v(
                                          "By granting the permission, a technical implementation request will be send to the corresponding team. The technical team is requested to perform all neccessary technical steps which are needed for the user to use the API. "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v(
                                            " Your grant will be a Data Domain Manager approval. If you are not a DDM on this API/Data please contact api-mgmt-support@schaeffler.com. "
                                          ),
                                        ]),
                                      ]),
                                  _c("v-card-text"),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.grantdialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.grantPermission()
                                            },
                                          },
                                        },
                                        [_vm._v(" Grant ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: {
                                width: "100%",
                                persistent: "",
                                "max-width": "490",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.permission.grantable,
                                                    expression:
                                                      "permission.grantable",
                                                  },
                                                ],
                                                staticClass: "white--text ml-2",
                                                attrs: {
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.error != null ||
                                                    _vm.actionDisabled,
                                                  color: "#006c27",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" Deny ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4222038477
                              ),
                              model: {
                                value: _vm.denydialog,
                                callback: function ($$v) {
                                  _vm.denydialog = $$v
                                },
                                expression: "denydialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "#006c27" },
                                        },
                                        [_vm._v("mdi-shield-remove-outline")]
                                      ),
                                      _vm._v(" Deny Permission "),
                                    ],
                                    1
                                  ),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "By denying this permission the permission requester will be informed. The permission requester will receive an email with your deny reason. "
                                    ),
                                  ]),
                                  _c(
                                    "v-form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.nop.apply(null, arguments)
                                        },
                                      },
                                      model: {
                                        value: _vm.denyReasonValid,
                                        callback: function ($$v) {
                                          _vm.denyReasonValid = $$v
                                        },
                                        expression: "denyReasonValid",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mt-3 mx-6",
                                        attrs: {
                                          label: "Deny Reason",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.counter,
                                          ],
                                          outlined: "",
                                          counter: "",
                                          maxlength: "200",
                                          color: "#006c27",
                                        },
                                        model: {
                                          value: _vm.denyReason,
                                          callback: function ($$v) {
                                            _vm.denyReason = $$v
                                          },
                                          expression: "denyReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.denydialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: !_vm.denyReasonValid,
                                            color: "green darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.denyPermission()
                                            },
                                          },
                                        },
                                        [_vm._v(" Deny ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.reasonDialog,
            callback: function ($$v) {
              _vm.reasonDialog = $$v
            },
            expression: "reasonDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text--secondary" }, [
                _vm._v("Additional Information"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-textarea", {
                    staticClass: "mt-3",
                    attrs: {
                      readonly: "",
                      color: "#006c27",
                      dense: "",
                      label: "Reason Text",
                      "hide-details": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.permEvent.reasonText,
                      callback: function ($$v) {
                        _vm.$set(_vm.permEvent, "reasonText", $$v)
                      },
                      expression: "permEvent.reasonText",
                    },
                  }),
                  _vm.permEvent.itSecDemand
                    ? _c(
                        "v-text-field",
                        {
                          staticClass: "mt-3",
                          attrs: {
                            readonly: "",
                            color: "#006c27",
                            dense: "",
                            label: "IT Security By Design Demand",
                            "hide-details": "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.permEvent.itSecDemand,
                            callback: function ($$v) {
                              _vm.$set(_vm.permEvent, "itSecDemand", $$v)
                            },
                            expression: "permEvent.itSecDemand",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    { name: "blur", rawName: "v-blur" },
                                  ],
                                  attrs: { color: "#006c27" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkITSec(
                                        _vm.permEvent.itSecURL
                                      )
                                    },
                                  },
                                },
                                [_vm._v("mdi-link")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-2 mb-4",
                      attrs: { color: "#006c27" },
                      on: {
                        click: function ($event) {
                          _vm.reasonDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }